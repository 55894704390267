import React from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from './store';
import { AccountType } from './store/interfaces/users/common/enums';
import history from './_helpers/history';

export { PrivateRoute };

function PrivateRoute({ children }) {
  const { user: authUser, isAuthenticated, token } = useSelector((x) => x.auth);

  React.useEffect(() => {
    if (!authUser || !isAuthenticated || !token) {
      // not logged in so redirect to login page with the return url
      window.location.assign('/login');
    } else if (
      isAuthenticated &&
      token &&
      [AccountType.ADMIN, AccountType.ASSOCIATE].includes(authUser.accountType)
    ) {
      window.location.assign('/admin');
    }
  }, [authUser, isAuthenticated, token]);

  return children;
}
