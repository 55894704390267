import { dispatch } from 'src/store';
import { api, setAuthToken, Utils } from 'src/store/api';
import { LoginDTO, UserDTO } from 'src/store/interfaces/users';
import { loginUser, setAuthLoading } from 'src/store/reducers/auth';
import history from 'src/_helpers/history';

export class AuthService {
  public static async login(val: LoginDTO): Promise<void> {
    try {
      dispatch(setAuthLoading(true));

      const res = await api.post('auth/login', JSON.stringify(val));

      setAuthToken(res.data.token);

      dispatch(
        loginUser({
          token: res.data.token,
          user: res.data.user,
          authLoading: false,
          isAuthenticated: true,
        }),
      );
    } catch (error) {
      Utils.renderErrors(error, 'Failed to login');
      dispatch(setAuthLoading(true));
    }
  }
  public static async register(val: UserDTO): Promise<void> {
    try {
      dispatch(setAuthLoading(true));

      await api.post('user/register', JSON.stringify(val));

      dispatch(setAuthLoading(false));

      Utils.renderSuccess('Registration successful, please login');
      setTimeout(() => {
        window.location.assign('/login');
      }, 3000);
    } catch (error) {
      Utils.renderErrors(error, 'Failed to register');
      dispatch(setAuthLoading(true));
    }
  }

  public static async logout(): Promise<void> {
    try {
      dispatch(
        loginUser({
          token: null,
          user: null,
          authLoading: false,
          isAuthenticated: false,
        }),
      );
      localStorage.removeItem('persist:loan-mgt-client');
      setAuthToken(null);
    } catch (error) {
      Utils.renderErrors(error, 'Failed to logout');
    }
  }

  public static async autoLogin(): Promise<void> {
    try {
      dispatch(setAuthLoading(true));

      const token = localStorage.getItem('loan-mgt-client');

      if (!token) {
        localStorage.removeItem('persist:loan-mgt-client');
        setAuthToken(null);
        AuthService.logout();
        return;
      }

      setAuthToken(token);

      const res = await api.get('/auth/current');

      dispatch(
        loginUser({
          token,
          user: res.data,
          authLoading: false,
          isAuthenticated: true,
        }),
      );
    } catch (error) {
      Utils.renderErrors(error, 'Failed to auto login');
      dispatch(setAuthLoading(false));
    }
  }

  public static async forgotPassword(email: string): Promise<void> {
    try {
      dispatch(setAuthLoading(true));

      await api.post('/user/password/forgot', JSON.stringify({ email }));

      dispatch(setAuthLoading(false));

      Utils.renderSuccess('Password reset link sent to your email');

      setTimeout(() => {
        window.location.assign('/password-request-sent');
      }, 3000);
    } catch (error) {
      Utils.renderErrors(error, 'Failed to reset password');
      dispatch(setAuthLoading(false));
    }
  }

  public static async resetPassword(
    password: string,
    token: string,
  ): Promise<void> {
    try {
      dispatch(setAuthLoading(true));
      const res = await api.post('/user/password/reset', {
        password,
        token,
      });

      dispatch(setAuthLoading(false));

      Utils.renderSuccess('Your password has been reset, please login');

      setTimeout(() => {
        window.location.assign('/login');
      }, 3000);
    } catch (error) {
      Utils.renderErrors(error, 'Failed to reset password');
      dispatch(setAuthLoading(false));
    }
  }

  public static async verifyAccount(token: string): Promise<{
    message: string;
    status: boolean;
  }> {
    try {
      dispatch(setAuthLoading(true));
      const res = await api.get(`/user/account/verify/${token}`);

      dispatch(setAuthLoading(false));

      return res.data;
    } catch (error) {
      Utils.renderErrors(error, 'Failed to verify account');
      dispatch(setAuthLoading(false));
    }
  }
}
