import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserPayload } from 'src/store/interfaces/users';

interface IAuthState {
  user: UserPayload | null;
  token: string | null;
  isAuthenticated: boolean;
  authLoading: boolean;
}

export const initialState: IAuthState = {
  user: null,
  token: null,
  isAuthenticated: false,
  authLoading: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginUser: (state, action: PayloadAction<IAuthState>) => {
      state.user = action.payload.user;
      state.token = action.payload.token;
      state.isAuthenticated = action.payload.isAuthenticated;
      state.authLoading = action.payload.authLoading;
    },
    logoutUser: (state) => {
      state.user = null;
      state.token = null;
      state.isAuthenticated = false;
      state.authLoading = false;
    },
    setAuthLoading: (state, action: PayloadAction<boolean>) => {
      state.authLoading = action.payload;
    },
  },
});

export const { loginUser, logoutUser, setAuthLoading } = authSlice.actions;
