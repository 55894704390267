import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PaymentObj } from 'src/store/interfaces/payments';

interface IPaymentState {
  payments: PaymentObj[];
  paymentsLoading: boolean;
}

interface IPaymentPayload {
  payment: PaymentObj;
  paymentsLoading: boolean;
}

const initialState: IPaymentState = {
  payments: [],
  paymentsLoading: false,
};

export const paymentSlice = createSlice({
  name: 'payments',
  initialState,
  reducers: {
    getPayments: (state, action: PayloadAction<IPaymentState>) => {
      state.payments = action.payload.payments;
      state.paymentsLoading = action.payload.paymentsLoading;
    },
    addPayment: (state, action: PayloadAction<IPaymentPayload>) => {
      state.payments = [action.payload.payment, ...state.payments];
      state.paymentsLoading = action.payload.paymentsLoading;
    },
    updatePayment: (state, action: PayloadAction<IPaymentPayload>) => {
      state.payments = state.payments.map((item) =>
        item.id === action.payload.payment.id ? action.payload.payment : item,
      );
      state.paymentsLoading = action.payload.paymentsLoading;
    },
    deletePayment: (state, action: PayloadAction<IPaymentPayload>) => {
      state.payments = state.payments.filter(
        (item) => item.id !== action.payload.payment.id,
      );
      state.paymentsLoading = action.payload.paymentsLoading;
    },
    setPaymentsLoading: (state, action: PayloadAction<boolean>) => {
      state.paymentsLoading = action.payload;
    },
  },
});

export const {
  getPayments,
  addPayment,
  updatePayment,
  deletePayment,
  setPaymentsLoading,
} = paymentSlice.actions;
