import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { InterestSheetObj } from 'src/store/interfaces/interest';

interface IInterestState {
  interest: InterestSheetObj[];
  interestLoading: boolean;
}

interface IInterestPayload {
  interest: InterestSheetObj;
  interestLoading: boolean;
}

const initialState: IInterestState = {
  interest: [],
  interestLoading: false,
};

export const interestSlice = createSlice({
  name: 'interest',
  initialState,
  reducers: {
    getInterestRates: (state, action: PayloadAction<IInterestState>) => {
      state.interest = action.payload.interest;
      state.interestLoading = action.payload.interestLoading;
    },
    addInterestRate: (state, action: PayloadAction<IInterestPayload>) => {
      state.interest = [action.payload.interest, ...state.interest];
      state.interestLoading = action.payload.interestLoading;
    },
    updateInterestRate: (state, action: PayloadAction<IInterestPayload>) => {
      state.interest = state.interest.map((item) =>
        item.id === action.payload.interest.id ? action.payload.interest : item,
      );
      state.interestLoading = action.payload.interestLoading;
    },
    deleteInterestRate: (state, action: PayloadAction<IInterestPayload>) => {
      state.interest = state.interest.filter(
        (item) => item.id !== action.payload.interest.id,
      );
      state.interestLoading = action.payload.interestLoading;
    },
    setInterestLoading: (state, action: PayloadAction<boolean>) => {
      state.interestLoading = action.payload;
    },
  },
});

export const {
  getInterestRates,
  addInterestRate,
  updateInterestRate,
  deleteInterestRate,
  setInterestLoading,
} = interestSlice.actions;
