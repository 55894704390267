import {
  Action,
  combineReducers,
  configureStore,
  ThunkAction,
} from '@reduxjs/toolkit';
import {
  TypedUseSelectorHook,
  useDispatch as useAppDispatch,
  useSelector as useAppSelector,
} from 'react-redux';
import {
  persistStore,
  persistReducer,
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { authSlice } from './reducers/auth';
import { interestSlice } from './reducers/interest';
import { loanSlice } from './reducers/loans';
import { paymentSlice } from './reducers/payments';
import { userSlice } from './reducers/users';
import { productSlice } from './reducers/products';

const persistConfig = {
  key: 'imagine-express',
  storage,
};

const rootReducer = combineReducers({
  auth: authSlice.reducer,
  interest: interestSlice.reducer,
  loans: loanSlice.reducer,
  payments: paymentSlice.reducer,
  users: userSlice.reducer,
  products: productSlice.reducer,
});

export const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware(getDefaultMiddleware) {
    return getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    });
  },
});

export const persistor = persistStore(store);

export const dispatch = store.dispatch;
const getState = store.getState;

export type AppDispatch = typeof dispatch;
export type RootState = ReturnType<typeof getState>;

export type AppStore = ReturnType<typeof rootReducer>;

export const useDispatch = () => useAppDispatch<typeof dispatch>();
export const useSelector: TypedUseSelectorHook<RootState> = useAppSelector;

export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;
