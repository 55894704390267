import { Suspense, lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import SidebarLayout from 'src/layouts/SidebarLayout';

import SuspenseLoader from 'src/components/SuspenseLoader';
import { useSelector } from './store';
import { AdminPrivateRoute } from './AdminPrivateRoute';
import { PrivateRoute } from './PrivateRoute';
import UserSidebarLayout from './layouts/SidebarLayout/UserSideBarLayout';
import { Dashboard } from '@mui/icons-material';

const Loader = (Component) => (props) =>
(
  <Suspense fallback={<SuspenseLoader />}>
    <Component {...props} />
  </Suspense>
);

const Login = Loader(lazy(() => import('src/content/applications/auth/Login')));

const Register = Loader(
  lazy(() => import('src/content/applications/auth/Registration')),
);

const RequestPasswordReset = Loader(
  lazy(() => import('src/content/applications/auth/RequestPasswordReset')),
);

const RequestPasswordSent = Loader(
  lazy(() => import('src/content/applications/auth/PasswordResetSent')),
);

const ResetPasswordPage = Loader(
  lazy(() => import('src/content/applications/auth/ResetpasswordPage')),
);

const AccountConfirmationPage = Loader(
  lazy(() => import('src/content/applications/auth/AccountConfirmationPage')),
);

const Users = Loader(lazy(() => import('src/content/applications/Users')));

const Loans = Loader(lazy(() => import('src/content/applications/Loans')));

const AdminNewLoan = Loader(
  lazy(() => import('src/content/applications/NewLoan')),
);

const AdminLoadDetails = Loader(
  lazy(() => import('src/content/applications/AdminLoanDetails')),
);

const LoanDetails = Loader(
  lazy(() => import('src/content/applications/LoanDetails')),
);

const AdminUserDetails = Loader(
  lazy(() => import('src/content/applications/UserDetails')),
);

const AdminInterestRates = Loader(
  lazy(() => import('src/content/applications/InterestRates')),
);

const AdminProducts = Loader(
  lazy(() => import('src/content/applications/Products')),
)

const AdminDashboard = Loader(
  lazy(() => import('src/content/applications/AdminLoanDetails/AdminDashboard')),
);

const UserLoans = Loader(
  lazy(() => import('src/content/applications/user-loans')),
);

const Status404 = Loader(
  lazy(() => import('src/content/pages/Status/Status404')),
);

export const Navigation = () => {
  const { isAuthenticated } = useSelector((state) => state.auth);

  return (
    <Routes>
      <Route path="/" element={<UserSidebarLayout />}>
        <Route
          path="/"
          element={
            <PrivateRoute>
              <UserLoans />
            </PrivateRoute>
          }
        />
        <Route
          path="/loan/:id"
          element={
            <PrivateRoute>
              <LoanDetails />
            </PrivateRoute>
          }
        />
      </Route>
      <Route
        path="/login"
        element={
          !isAuthenticated /* || !user || !token */ ? (
            <Login />
          ) : (
            <Navigate to="/" />
          )
        }
      />
      <Route
        path="/request-password-reset"
        element={
          !isAuthenticated /* || !user || !token */ ? (
            <RequestPasswordReset />
          ) : (
            <Navigate to="/" />
          )
        }
      />
      <Route
        path="/password-request-sent"
        element={
          !isAuthenticated /* || !user || !token */ ? (
            <RequestPasswordSent />
          ) : (
            <Navigate to="/" />
          )
        }
      />
      <Route
        path="/reset-password/:token"
        element={
          !isAuthenticated /* || !user || !token */ ? (
            <ResetPasswordPage />
          ) : (
            <Navigate to="/" />
          )
        }
      />
      <Route
        path="/auth/confirm/:token"
        element={
          !isAuthenticated /* || !user || !token */ ? (
            <AccountConfirmationPage />
          ) : (
            <Navigate to="/" />
          )
        }
      />
      <Route
        path="/register"
        element={
          !isAuthenticated /* || !user || !token */ ? (
            <Register />
          ) : (
            <Navigate to="/" />
          )
        }
      />
      <Route path="/admin" element={<SidebarLayout />}>
        <Route
          path="/admin"
          element={
            <AdminPrivateRoute>
              <AdminDashboard />
            </AdminPrivateRoute>
          }
        />
        <Route
          path="/admin/users"
          element={
            <AdminPrivateRoute>
              <Users />
            </AdminPrivateRoute>
          }
        />
        <Route
          path="/admin/loans"
          element={
            <AdminPrivateRoute>
              <Loans />
            </AdminPrivateRoute>
          }
        />
        <Route
          path="/admin/loans/:id"
          element={
            <AdminPrivateRoute>
              <AdminLoadDetails />
            </AdminPrivateRoute>
          }
        />
        <Route
          path="/admin/user/:id"
          element={
            <AdminPrivateRoute>
              <AdminUserDetails />
            </AdminPrivateRoute>
          }
        />
        <Route
          path="/admin/new-loan"
          element={
            <AdminPrivateRoute>
              <AdminNewLoan />
            </AdminPrivateRoute>
          }
        />
        <Route
          path="/admin/interest-rates"
          element={
            <AdminPrivateRoute>
              <AdminInterestRates />
            </AdminPrivateRoute>
          }
        />
        <Route
          path="/admin/products"
          element={
            <AdminPrivateRoute>
              <AdminProducts />
            </AdminPrivateRoute>
          }
        />
        <Route path="*" element={<Status404 />} />
      </Route>
      <Route path="*" element={<Status404 />} />
    </Routes>
  );
};
