import { LoanObj } from 'src/store/interfaces/loans';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ILoanState {
  loans: LoanObj[];
  loansLoading: boolean;
}

interface ILoanPayload {
  loan: LoanObj;
  loansLoading: boolean;
}

const initialState: ILoanState = {
  loans: [],
  loansLoading: false,
};

export const loanSlice = createSlice({
  name: 'loans',
  initialState,
  reducers: {
    getLoans: (state, action: PayloadAction<ILoanState>) => {
      state.loans = action.payload.loans;
      state.loansLoading = action.payload.loansLoading;
    },
    addLoan: (state, action: PayloadAction<ILoanPayload>) => {
      state.loans = [action.payload.loan, ...state.loans];
      state.loansLoading = action.payload.loansLoading;
    },
    updateLoan: (state, action: PayloadAction<ILoanPayload>) => {
      state.loans = state.loans.map((item) =>
        item.id === action.payload.loan.id ? action.payload.loan : item,
      );
      state.loansLoading = action.payload.loansLoading;
    },
    deleteLoan: (state, action: PayloadAction<ILoanPayload>) => {
      state.loans = state.loans.filter(
        (item) => item.id !== action.payload.loan.id,
      );
      state.loansLoading = action.payload.loansLoading;
    },
    setLoansLoading: (state, action: PayloadAction<boolean>) => {
      state.loansLoading = action.payload;
    },
  },
});

export const { getLoans, addLoan, updateLoan, deleteLoan, setLoansLoading } =
  loanSlice.actions;
