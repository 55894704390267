import { useLocation, useNavigate, useRoutes } from 'react-router-dom';
import { Navigation } from 'src/router';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import { CssBaseline } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import React from 'react';
import { AuthService } from './store/actions/auth';
import history from './_helpers/history';

function App() {
  history.location = useLocation();
  history.navigate = useNavigate();

  React.useEffect(() => {
    window.addEventListener('storage', () => {
      const token = localStorage.getItem('loan-mgt-client');

      if (!token) {
        AuthService.logout();
      }
    });
  }, []);

  React.useEffect(() => {
    AuthService.autoLogin();
  }, []);

  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <CssBaseline />
        {/* {content} */}
        <Navigation />
      </LocalizationProvider>
    </ThemeProvider>
  );
}
export default App;
