import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserPayload } from 'src/store/interfaces/users';

interface IUserState {
  users: UserPayload[];
  usersLoading: boolean;
}

interface IUserPayload {
  user: UserPayload;
  usersLoading: boolean;
}

const initialState: IUserState = {
  users: [],
  usersLoading: false,
};

export const userSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    getUsers: (state, action: PayloadAction<IUserState>) => {
      state.users = action.payload.users;
      state.usersLoading = action.payload.usersLoading;
    },
    addUser: (state, action: PayloadAction<IUserPayload>) => {
      state.users = [action.payload.user, ...state.users];
      state.usersLoading = action.payload.usersLoading;
    },
    updateUser: (state, action: PayloadAction<IUserPayload>) => {
      state.users = state.users.map((item) =>
        item.id === action.payload.user.id ? action.payload.user : item,
      );
      state.usersLoading = action.payload.usersLoading;
    },
    deleteUser: (state, action: PayloadAction<IUserPayload>) => {
      state.users = state.users.filter(
        (item) => item.id !== action.payload.user.id,
      );
      state.usersLoading = action.payload.usersLoading;
    },
    setUsersLoading: (state, action: PayloadAction<boolean>) => {
      state.usersLoading = action.payload;
    },
  },
});

export const { getUsers, addUser, updateUser, deleteUser, setUsersLoading } =
  userSlice.actions;
