import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from './store';
import { AccountType } from './store/interfaces/users/common/enums';
import history from './_helpers/history';

export { AdminPrivateRoute };

function AdminPrivateRoute({ children }) {
  const { user: authUser, isAuthenticated, token } = useSelector((x) => x.auth);

  React.useEffect(() => {
    if (!authUser || !isAuthenticated || !token) {
      // not logged in so redirect to login page with the return url
      window.location.assign('/login');
    } else if (
      isAuthenticated &&
      authUser.accountType === AccountType.USER &&
      token
    ) {
      window.location.assign('/');
    }
  }, [authUser, isAuthenticated, token]);

  // authorized so return child components
  return children;
}
