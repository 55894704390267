import axios from 'axios';
import { toast } from 'react-toastify';
import { AuthService } from './actions/auth';

export const baseURL = 'https://eldinero-money-api-production.up.railway.app';
// export const baseURL = 'http://localhost:1992';

export const api = axios.create({
  baseURL: `${baseURL}/api`,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

api.interceptors.response.use(
  (res) => res,
  (err) => {
    if (err.response.status === 401) {
      // do something
      AuthService.logout();
    }
    return Promise.reject(err);
  },
);

export const setAuthToken = (token: string | null): void => {
  try {
    if (token) {
      localStorage.setItem('loan-mgt-client', token);
      api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
      localStorage.removeItem('loan-mgt-client');
      localStorage.removeItem('persist:loan-mgt-client');
      delete api.defaults.headers.common['Authorization'];
    }
  } catch (error) {
    console.log(error);
  }
};

export class Utils {
  public static async getAuthToken(): Promise<string | null> {
    return localStorage.getItem('smart-forms');
  }

  public static renderErrors(error: any, message: string): void {
    // if (Platform.OS === 'ios') {
    if (error.response) {
      const errors = error.response.data.errors;

      if (errors) {
        for (const err of errors) {
          toast.error(err.msg);
        }
      } else {
        if (typeof error.response.data.message === 'object') {
          const errList = error.response.data.message;
          for (const err of errList) {
            toast.error(err);
          }
          console.log(error.response.data.message);
        }
        toast.error(error.response.data.message || message);
      }
      console.log(error);
    } else if (error.request) {
      toast.error(message);
      console.log(error);
      console.log(error.request);
    } else {
      toast.error(message);

      console.log(error);
      console.log(error.message);
    }
  }

  public static renderSuccess(message: string): void {
    toast.success(message);
  }
}

export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
