import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IProductsObj } from 'src/store/interfaces/products';

interface IProductState {
  products: IProductsObj[];
  productLoading: boolean;
}

interface IProductPayload {
  products: IProductsObj;
  productLoading: boolean;
}

const initialState: IProductState = {
  products: [],
  productLoading: false,
};

export const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    getProducts: (state, action: PayloadAction<IProductState>) => {
      state.products = action.payload.products;
      state.productLoading = action.payload.productLoading;
    },
    addProduct: (state, action: PayloadAction<IProductPayload>) => {
      state.products = [action.payload.products, ...state.products];
      state.productLoading = action.payload.productLoading;
    },
    updateProduct: (state, action: PayloadAction<IProductPayload>) => {
      state.products = state.products.map((item) =>
        item.id === action.payload.products.id ? action.payload.products : item,
      );
      state.productLoading = action.payload.productLoading;
    },
    deleteProduct: (state, action: PayloadAction<IProductPayload>) => {
      state.products = state.products.filter(
        (item) => item.id !== action.payload.products.id,
      );
      state.productLoading = action.payload.productLoading;
    },
    setProductLoading: (state, action: PayloadAction<boolean>) => {
      state.productLoading = action.payload;
    },
  },
});

export const {
  getProducts,
  addProduct,
  updateProduct,
  deleteProduct,
  setProductLoading,
} = productSlice.actions;